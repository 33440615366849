import { Box, Card, Link, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Card)(
    ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(4)};
`
);

export default function Footer() {
    const appDetails = JSON.parse(process.env.REACT_APP_APP_DETAILS);
    return (
        <FooterWrapper className="footer-wrapper">
            <Box
                p={4}
                display={{ xs: 'block', md: 'flex' }}
                alignItems="center"
                textAlign={{ xs: 'center', md: 'left' }}
                justifyContent="space-between"
            >
                <Box>
                    <Typography variant="subtitle1">
                        &copy; {new Date().getFullYear()} - {appDetails.name}
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        pt: { xs: 2, md: 0 }
                    }}
                    variant="subtitle1"
                >
                    Developed by{' '}
                    <Link
                        href={process.env.REACT_APP_COMPANY_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {process.env.REACT_APP_COMPANY_NAME}
                    </Link>
                </Typography>
            </Box>
        </FooterWrapper>
    );
}
