import { Box } from '@mui/material';
import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';

export default function HeaderButtons() {
  return (
    <Box
      sx={{
        mr: 1
      }}
    >
      {
        process.env.NODE_ENV === 'development' && 
        <HeaderNotifications />
      }
      <LanguageSwitcher />
    </Box>
  );
}
