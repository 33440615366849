import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Profile = Loader(lazy(() => import('src/content/profile')));
const SingleProfile = Loader(lazy(() => import('src/content/profile/single')));

const profileRoute = [
  { 
    path: '/', // only for self i.e logged in user, 
    element: <Profile />
  },
  { 
    path: ':userSlug', // to view other user
    element: <SingleProfile />
  },
];

export default profileRoute;
