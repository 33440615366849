import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

  const Order = Loader(lazy(() => import('src/content/order')));
  const OrderDetails = Loader(lazy(() => import('src/content/order/OrderDetails')));

const shopRoutes = [
  { 
    path: '/',
    element: <Order />
  },
  { 
    path: '/:orderSlug',
    element: <OrderDetails />
  }
];

export default shopRoutes;
