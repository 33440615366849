import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Shop

const CreateShop = Loader(lazy(() => import('src/content/shop/create')));
const ActiveShop = Loader(lazy(() => import('src/content/shop/list')));

const shopRoutes = [
  { 
    path: '/',
    element: <Navigate to="create" replace />
  },
  {
    path: 'create',
    element: <CreateShop />
  },
  {
    path: 'active',
    element: <ActiveShop />
  },
];

export default shopRoutes;
