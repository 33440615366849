import { Box } from '@mui/material';
import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';
import Chat from './Chat';

export default function HeaderButtons() {
  return (
    <Box>
      <HeaderNotifications />
      <LanguageSwitcher />
      {
        process.env.NODE_ENV === 'development' && 
        <Chat />
      }
    </Box>
  );
}
