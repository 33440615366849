import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Slider

const Slider = Loader(
  lazy(() => import('src/content/slider'))
);

const slider = [
  {
    path: '/',
    element: <Slider />
  },
  // {
  //   path: 'shop',
  //   element: <ShopSettings />
  // }
];

export default slider;
