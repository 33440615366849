import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Reports = Loader(lazy(() => import('src/content/dashboards/Reports')));

const shopRoutes = [
  { 
    path: '/',
    element: <Reports />
  },

];

export default shopRoutes;
