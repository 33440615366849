import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Product = Loader(lazy(() => import('src/content/product')));
const SingleProduct = Loader(lazy(() => import('src/content/product/single/index')));
const ProductCategoryList = Loader(lazy(() => import('src/content/productCategory/list')));
const ProductCategoryCreate = Loader(lazy(() => import('src/content/productCategory/create')));

const productRoute = [
  { 
    path: '/',
    element: <Product />
  },
  { 
    path: ':productSlug',
    element: <SingleProduct />
  },
  {
    path: 'category',
    element: <ProductCategoryList />
  },
  {
    path: 'category/create',
    element: <ProductCategoryCreate />
  },
];

export default productRoute;
