// import { useRef, useState } from 'react';
// import useAuth from 'src/hooks/useAuth';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  alpha,
  List,
  ListItem, 
  ListItemText,
//   Popover,
  Typography,
  styled,
//   useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor, 0.6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`
);

export default function PopoverMenu({ handleClose, logout}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
//   const { logout } = useAuth();
  let user = localStorage.getItem('user') || '';
  user = user ? JSON.parse(user) : '';

//   const ref = useRef(null);
//   const [isOpen, setOpen] = useState(open);


//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
    <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel className="popoverTypo" variant="body1">
              {user.name} abc
            </UserBoxLabel>
            <UserBoxDescription className="popoverTypo" variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={`/${location.pathname.split('/')[1]}/profile`}
            component={NavLink}
          >
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={`/${
              location.pathname.split('/')[1]
            }/management/mailbox/inbox`}
            component={NavLink}
          >
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <ListItem
            onClick={() => {
              handleClose();
            }}
            button
            to={`/${
              location.pathname.split('/')[1]
            }/applications/projects-board`}
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Projects')} />
          </ListItem>
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
    </>
    // <Popover
    //     disableScrollLock
    //     anchorEl={ref.current}
    //     onClose={handleClose}
    //     open={isOpen}
    //     anchorOrigin={{
    //       vertical: 'center',
    //       horizontal: 'center'
    //     }}
    //     transformOrigin={{
    //       vertical: 'center',
    //       horizontal: 'center'
    //     }}
    //   >
    //     <MenuUserBox
    //       sx={{
    //         minWidth: 210
    //       }}
    //       display="flex"
    //     >
    //       <Avatar variant="rounded" alt={user.name} src={user.avatar} />
    //       <UserBoxText>
    //         <UserBoxLabel className="popoverTypo" variant="body1">
    //           {user.name}
    //         </UserBoxLabel>
    //         <UserBoxDescription className="popoverTypo" variant="body2">
    //           {user.jobtitle}
    //         </UserBoxDescription>
    //       </UserBoxText>
    //     </MenuUserBox>
    //     <Divider
    //       sx={{
    //         mb: 0
    //       }}
    //     />
    //     <List
    //       sx={{
    //         p: 1
    //       }}
    //       component="nav"
    //     >
    //       <ListItem
    //         onClick={() => {
    //           handleClose();
    //         }}
    //         button
    //         to={`/${location.pathname.split('/')[1]}/profile`}
    //         component={NavLink}
    //       >
    //         <AccountBoxTwoToneIcon fontSize="small" />
    //         <ListItemText primary={t('Profile')} />
    //       </ListItem>
    //       <ListItem
    //         onClick={() => {
    //           handleClose();
    //         }}
    //         button
    //         to={`/${
    //           location.pathname.split('/')[1]
    //         }/management/mailbox/inbox`}
    //         component={NavLink}
    //       >
    //         <InboxTwoToneIcon fontSize="small" />
    //         <ListItemText primary={t('Inbox')} />
    //       </ListItem>
    //       {
    //         process.env.NODE_ENV === 'development' && 
    //         <ListItem
    //           onClick={() => {
    //             handleClose();
    //           }}
    //           button
    //           to={`/${
    //             location.pathname.split('/')[1]
    //           }/applications/projects-board`}
    //           component={NavLink}
    //         >
    //           <AccountTreeTwoToneIcon fontSize="small" />
    //           <ListItemText primary={t('Projects')} />
    //         </ListItem>
    //       }
    //     </List>
    //     <Divider />
    //     <Box m={1}>
    //       <Button color="primary" fullWidth onClick={handleLogout}>
    //         <LockOpenTwoToneIcon
    //           sx={{
    //             mr: 1
    //           }}
    //         />
    //         {t('Sign out')}
    //       </Button>
    //     </Box>
    //   </Popover>
  );
}