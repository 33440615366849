import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

  const Payment = Loader(lazy(() => import('src/content/payment')));
  const PaymentDetails = Loader(lazy(() => import('src/content/payment/PaymentDetails')));

const shopRoutes = [
  { 
    path: '/',
    element: <Payment />
  },
  { 
    path: '/:paymentSlug',
    element: <PaymentDetails />
  }
];

export default shopRoutes;
