import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

 // Compliance
const Compliance = Loader(lazy(() => import('src/content/Compliance')));

const dashboardsRoutes = [
  {
    path: '/',
    element: <Compliance />
  }
];

export default dashboardsRoutes;
