import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management 

const Users = Loader(lazy(() => import('src/content/management/Users')));
const ShopUsers = Loader(lazy(() => import('src/content/Users')));
const ShopUser = Loader(lazy(() => import('src/content/Users/single')));
const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);

const SystemSetup = Loader(lazy(() => import('src/content/SystemSetup')));
const Roles = Loader(lazy(() => import('src/content/role')));
const Permissions = Loader(lazy(() => import('src/content/permission')));
const Banks = Loader(lazy(() => import('src/content/bank')));
const Currencies = Loader(lazy(() => import('src/content/currency')));
const ProductCategories = Loader(lazy(() => import('src/content/systemProductCategory')));
const ProductSubCategories = Loader(lazy(() => import('src/content/systemProductSubCategory')));
const Countries = Loader(lazy(() => import('src/content/country')));

const Projects = Loader(lazy(() => import('src/content/management/Projects')));
const Invoices = Loader(lazy(() => import('src/content/management/Invoices')));
const SingleInvoice = Loader(
  lazy(() => import('src/content/management/Invoices/single'))
);
const Products = Loader(lazy(() => import('src/content/management/Products')));
const CreateProduct = Loader(
  lazy(() => import('src/content/management/Products/create'))
);
const SingleProduct = Loader(
  lazy(() => import('src/content/management/Products/single'))
);
const Shop = Loader(lazy(() => import('src/content/management/Products/shop')));

const Mailbox = Loader(lazy(() => import('src/content/mailbox/')));
const EmailReceivers = Loader(lazy(() => import('src/content/mailbox/EmailReceivers')));

const managementRoutes = [
  {
    path: '/',
    element: <Navigate to="users" replace />
  },
  {
    path: 'users',
    children: [
      {
        path: '/',
        element: <ShopUsers />
      },
      {
        path: 'list',
        element: <Users />
      },
      {
        path: 'one',
        children: [
          {
            path: '/',
            element: <Navigate to="../" replace />
          },
          {
            path: ':userId',
            element: <ShopUser />
          }
        ]
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            // path: ':userId',
            path: ':userSlug',
            element: <SingleUser />
          }
        ]
      }
    ]
  },
  {
    path: 'system-setup',
    children: [
      {
        path: '/',
        element: <SystemSetup />
      }
    ]
  },
  {
    path: 'roles',
    children: [
      {
        path: '/',
        element: <Roles />
      }
    ]
  },
  {
    path: 'permissions',
    children: [
      {
        path: '/',
        element: <Permissions />
      }
    ]
  },
  {
    path: 'banks',
    children: [
      {
        path: '/',
        element: <Banks />
      }
    ]
  },
  {
    path: 'currencies',
    children: [
      {
        path: '/',
        element: <Currencies />
      }
    ]
  },
  {
    path: 'product-categories',
    children: [
      {
        path: '/',
        element: <ProductCategories />
      }
    ]
  },
  {
    path: 'product-sub-categories',
    children: [
      {
        path: '/',
        element: <ProductSubCategories />
      }
    ]
  },
  {
    path: 'countries',
    children: [
      {
        path: '/',
        element: <Countries />
      }
    ]
  },
  {
    path: 'email',
    children: [
      {
        path: '/',
        element: <Mailbox />
      }
    ]
  },

  {
    path: 'mailbox',
    children: [
      {
        path: '/',
        element: <Mailbox />
      },
      {
        path: 'inbox',
        element: <Mailbox />
      },
      {
        path: 'tag/:labelTag',
        element: <Mailbox />
      },
      {
        path: 'tag/:labelTag/:mailboxCategory',
        element: <Mailbox />
      },
      {
        path: ':categoryTag',
        element: <Mailbox />
      },
      {
        path: ':categoryTag/:mailboxCategory',
        element: <Mailbox />
      },
      {
        path: 'email-receivers/:emailSlug',
        element: <EmailReceivers />
      }
    ]
  },
  {
    path: 'projects',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Projects />
      }
    ]
  },
  {
    path: 'commerce',
    children: [
      {
        path: '/',
        element: <Navigate to="shop" replace />
      },
      {
        path: 'shop',
        element: <Shop />
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Products />
          },
          {
            path: 'create',
            element: <CreateProduct />
          },
          {
            path: 'single',
            children: [
              {
                path: '/',
                element: <Navigate to="1" replace />
              },
              {
                path: ':productId',
                element: <SingleProduct />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'invoices',
    children: [
      {
        path: '/',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Invoices />
      },
      {
        path: 'single',
        children: [
          {
            path: '/',
            element: <Navigate to="1" replace />
          },
          {
            path: ':invoiceId',
            element: <SingleInvoice />
          }
        ]
      }
    ]
  }
];

export default managementRoutes;
