import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Test = Loader(lazy(() => import('src/content/Test/Test')));

const TestRoute = [
  { 
    path: '/',
    element: <Test />
  }
];

export default TestRoute;
